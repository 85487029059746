import React, { useEffect } from "react";
import { Button, Container, Divider, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { useHistory } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import {
  useCurrentUserQuery,
  useResetEventsMutation
} from "../../app/services/mastraApi";
import { enqueueSnackbar } from "notistack";
import ClearAllIcon from '@mui/icons-material/ClearAll';

export default function IntegrationsPage() {

  const history = useHistory();

  return (
    <Container>
      <Stack direction="column" gap={4}>
        <Divider>
          <Chip label="Integrations" />
        </Divider>
        <TelegramButton />
        <Button variant="contained" onClick={() => history.push("/multitrigger")} startIcon={<TouchAppIcon />}>
          Trigger Page
        </Button>
        <Button variant="contained" onClick={() => history.push("/integrations/sound")} startIcon={<VolumeUpIcon />}>
          Sound settings
        </Button>

      </Stack>
    </Container>
  );
}

function TelegramButton() {
  const {
    data: { notif_token },

  } = useCurrentUserQuery();

  return (<Button
    variant="contained"
    startIcon={<TelegramIcon />}
    aria-label="share"
    component="a"
    target="_blank"
    href={"http://t.me/WillieNotificationsBot?start=" + notif_token}
  >
    Start Telegram Bot
  </Button>)
}
