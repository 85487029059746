import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Chip, Divider, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useEventContext } from "./useEventContext";
import Badge from '@mui/material/Badge';
import { useAuth } from '../auth/useAuth';
import { ResetEventsButton } from "./ResetEventsButton";
import { MarkAllAsReadButton } from "./MarkAllAsReadButton";
import { PlaceHolder, LoadingPlaceholder, ErrorPlaceholder } from "../PlaceHolders";
import TimeAgo from 'react-timeago'
import WatchOffIcon from '@mui/icons-material/WatchOff';

export default function EventListPage() {
  const [searchText, setSearchText] = useState("");
  const { isAdmin } = useAuth();

  const {
    events,
    isEventsLoading,
    isEventsError
  } = useEventContext();

  const eventFilter = (e) => {
    const query = searchText.toLowerCase();
    if (searchText === "") return true;
    return (
      e.title.toLowerCase().includes(query) ||
      e.description.toLowerCase().includes(query)
    );
  };

  const handleSearchTextChange = (event) => {
    // handles the search text box change
    setSearchText(event.target.value);
  };

  return (
    <Container>
      <Divider>
        <Chip label="Active Events" />
      </Divider>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={handleSearchTextChange}
          fullWidth
        />
      </Search>
      <Stack direction={{ xs: "column", sm: "row" }} gap={2} sx={{ marginTop: "10px" }}>
        {isAdmin && <ResetEventsButton />}
        <MarkAllAsReadButton />
      </Stack>
      <List>
        {isEventsError ? <ErrorPlaceholder />
          : isEventsLoading ? <LoadingPlaceholder />
            : events.filter(eventFilter).length === 0 ? <PlaceHolder text="No active events" icon={<WatchOffIcon fontSize="large" />} />
              : (
                events.filter(eventFilter).map((elem, id) => {
                  return (

                    <ListItem
                      key={id}
                      sx={{
                        bgcolor: "#fafafa",
                        borderRadius: "22px",
                        padding: "20px",
                        boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      <ListItemAvatar>
                        <Badge
                          variant="dot"
                          color="secondary"
                          invisible={elem.is_read}
                          // Fixes the badge going over the bottom navigation bar
                          sx={{ zIndex: 0 }}
                        >
                          <Avatar sx={{ bgcolor: "#1976d2" }}>{elem.title[0]}</Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={elem.description}
                        secondary={<TimeAgo date={elem.updated_at} />}
                      />
                    </ListItem>
                  );
                })
              )}
      </List>
    </Container >
  );
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
