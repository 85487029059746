import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { grey } from "@mui/material/colors";
import ScrollToTop from "./features/ScrollToTop";
import store from "./app/store";
import App from "./App";



const theme = createTheme({
  palette: {
    text: {
      primary: grey["500"],
      // secondary: grey[700],
    },
    primary: {
      main: "#624E90",
      contrastText: grey[50],
    },
    secondary: {
      main: "#5C2DC8",
    },
    background: {
      default: "#fafafa",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          // shadow
          boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
