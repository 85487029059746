import { List } from "@mui/material";
import React, { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useEventsQuery, useRemoveEventMutation } from "../../app/services/mastraApi";
import {
  ErrorPlaceholder,
  LoadingPlaceholder,
  NoResultsPlaceholder
} from "../PlaceHolders";
import { ListItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconBtn } from "../Btn";
import Typography from '@mui/material/Typography';

export default function EventDeletionList(props) {
  const { data: events, isLoading, isError } = useEventsQuery();
  const [removeEvent, { isLoading: isRemoveLoading, isSuccess: isRemoveSuccess, isError: isRemoveError }] = useRemoveEventMutation();


  // Show success/error message
  useEffect(() => {
    if (isRemoveSuccess) {
      enqueueSnackbar("Event removed", { variant: "success" });
    } else if (isRemoveError) {
      enqueueSnackbar("Error while removing event", { variant: "error" });
    }
  }, [isRemoveSuccess, isRemoveError]);


  return (
    <>
      <Typography variant="h6">Available Events</Typography>
      {isError ? (
        <ErrorPlaceholder />
      ) : isLoading ? (
        <LoadingPlaceholder />
      ) :
        events.length === 0 ? (
          <NoResultsPlaceholder />
        ) : (
          <List>
            {events.map((field) => (

              <ListItem
                key={`${field.id_cu}-${field.id}`}
                sx={{
                  bgcolor: "#fafafa",
                  borderRadius: "22px",
                  padding: "15px",
                  boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
                  width: "100%",
                  mt: 2,
                }}
                secondaryAction={
                  <IconBtn
                    onClick={() => removeEvent({ id_event: field.id, id_cu: field.id_cu })}
                    sx={{ ml: 2 }}
                    askConfirm
                    loading={isRemoveLoading}
                  >
                    <DeleteIcon />
                  </IconBtn>
                }


              >
                {`CU ${field.id_cu}\t- Event ${field.id}\t- ${field.description}`}
              </ListItem>
            ))}
          </List>)}
    </>

  )
}
