import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import { useUrlQuery } from "../useUrlQuery";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

/**
 * Provide a react-dom-router route which handles conditional rendering
 * renders privateComponent if user is logged in
 * renders publicComponent if user is NOT logged in
 *
 * NOTE: redirect destination can be overridden at runtime by adding ?redirect=/my/page to the URL
 */
export function SessionSwitchRoute({
  privateComponent,
  publicComponent,
  ...other
}) {
  const { isLoggedIn, isLoading } = useAuth();

  // Prepare redirects
  const redirectUrl = useUrlQuery().get("redirect");

  // If no private component is specified, take user to its home page
  // and also replace the public page from history so if the user presses
  // back button he is not stuck
  privateComponent = privateComponent || <Redirect to={redirectUrl || "/"} />;

  // If no public component is present, redirect to login by default
  // removing the private page from history
  publicComponent = publicComponent || (
    <Redirect to={redirectUrl || "/login"} />
  );

  const indefiniteComponent = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <Route
      {...other}
      render={({ _location }) =>
        isLoading
          ? indefiniteComponent
          : isLoggedIn
            ? privateComponent
            : publicComponent
      }
    />
  );
}
