import React from 'react'
import SelectInput from '../SelectInput'
import { useUsersQuery } from '../../app/services/mastraApi'

export default function UserSelectInput(props) {

    const { data: users } = useUsersQuery();

    return (
        <SelectInput
            label="Users"
            options={users?.map(user => ({ value: user.id, label: user.username })) || []}
            {...props}
        />
    )
}
