import React from 'react'
import useSound from 'use-sound';
import notification1 from "./sounds/notification1.wav"
import { useSoundContext } from './useSoundContext';
import { Button } from '@mui/material';

/**
 * SoundPlayer is a wrapper around SoundPlayerCore that is used to force a re-render when the sound mode changes.
 * This is necessary because use-sound does not support changing the parameters dynamically.
 */
export default function SoundPlayer() {
    const { playSound, isSoundLooping } = useSoundContext();

    return <SoundPlayerCore
        // WORKAROUND: The trick here is to change the key when the sound mode changes to force a re-render
        key={isSoundLooping}
        playSound={playSound}
        isSoundLooping={isSoundLooping}
    />

}

/**
 * This component is responsible for rendering a button that can be used to test the sound.
 */
export function SoundPlayerTestButton(props) {

    const [isTestPlaying, setIsTestPlaying] = React.useState(false);

    const handleTestButtonClick = () => {
        setIsTestPlaying(true);
        setTimeout(() => {
            setIsTestPlaying(false);
        }, 1000);
    }

    return (<>
        <Button
            variant="outlined"
            onClick={handleTestButtonClick}
            disabled={isTestPlaying}
            {...props}
        >
            {isTestPlaying ? "Playing..." : "Test"}
        </Button>
        <SoundPlayerCore playSound={isTestPlaying} />
    </>)
}

/**
 * This component is responsible for playing the sound.
 * It needs to be re-rendered when the sound mode changes.
 */
function SoundPlayerCore(props) {
    const [play, { stop }] = useSound(notification1,
        // These are the props that don't get updated
        { loop: props.isSoundLooping }
    );

    React.useEffect(() => {
        console.debug("SoundPlayer mounted")

        return () => {
            console.debug("SoundPlayer unmounted")
        }
    }, [])

    React.useEffect(() => {
        if (props.playSound) {
            console.debug("Playing sound")
            play()
        } else {
            console.debug("Stopping sound")
            stop()
        }

        return () => {
            // Make sure to stop the sound when the component unmounts
            console.debug("Stopping sound")
            stop()
        }
    }, [props.playSound, play, stop])

    return null;
}
