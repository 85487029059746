import { React, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useLoginMutation } from "../../app/services/mastraApi";
import { enqueueSnackbar } from "notistack";

export default function Login() {
  const [login, { isLoading, isError, isSuccess }] = useLoginMutation();

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  // Handle form submission
  const onSubmit = (data) => {
    login({ ...data });
  };

  // On successful login, show a toast message
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Login successful", { variant: "success" })
    }
  }, [isSuccess]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Willie
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1 }}
          >
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  name="username"
                  label="Username"
                  type="username"
                  error={errors.username}
                  autoFocus
                  helperText={errors.username && "Campo obbligatorio"}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  error={errors.password}
                  helperText={errors.password && "Ricontrolla la password"}
                  {...field}
                />
              )}
            />
            {isError && (
              <Alert severity="error">Ricontrolla username e password</Alert>
            )}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading}
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </>
  );
}
