import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useHistory } from "react-router-dom";
import { useLogoutMutation } from "../../app/services/mastraApi";
import { useAuth } from "../auth/useAuth";
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { IconBtn } from '../Btn';

export default function FormDialog() {
    const [triggerLogout] = useLogoutMutation();
    const { isAdmin } = useAuth();

    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    // If the view query parameter is set to kiosk, hide the bottom navigation
    const isKioskMode = new URLSearchParams(location.search).get("view") === "kiosk";

    const handleOpenDialog = () => {
        setOpen(true);
    }

    const handleCloseDialog = () => {
        setOpen(false);
    }

    const handleLock = () => {
        // add ?view=kiosk to the URL
        history.push("/multitrigger?view=kiosk");
        if (isAdmin) {
            triggerLogout();
        }
        enqueueSnackbar("Simplified view enabled", { variant: "info" });
    };

    const handleUnlock = (e) => {
        e.preventDefault();

        // Check password is "unlock"
        const password = e.target.password.value;
        if (password !== "unlock") {
            enqueueSnackbar("Wrong password", { variant: "error" });
            handleCloseDialog();
            return;
        }

        history.push("/multitrigger");
        handleCloseDialog();
        enqueueSnackbar("Simplified view disabled", { variant: "info" });
    };


    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconBtn
                    fakeDelayMs={0}
                    askConfirm={!isKioskMode}
                    confirmTitle="Lock view"
                    confirmMsg="Are you sure you want to lock the view into simplified mode? You will need to enter the password to unlock it."
                    onClick={isKioskMode ? handleOpenDialog : handleLock}>
                    {isKioskMode ? <LockIcon /> : <LockOpenIcon />}
                </IconBtn>
            </Box>
            <Dialog open={open} onClose={handleCloseDialog}>
                <form noValidate autoComplete="off" onSubmit={handleUnlock}>
                    <DialogTitle>Unlock simplified view</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the unlock password to unlock the simplified view.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            type="password"
                            id="unlock-password"
                            name="password"
                            label="Password"
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{ mr: 2 }}
                    >
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit">Unlock</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment >
    );
}
