import React, { createContext, useContext } from 'react';
import { useLocalStorage } from '../useLocalStorage';
import { useEventContext } from "../event/useEventContext";

const SoundContext = createContext(undefined);

function SoundProvider({ children }) {
    // Sound mode is either "on", "off" or "loop"
    const [soundMode, setSoundMode] = useLocalStorage('soundMode', "on");
    const { unreadEventCount } = useEventContext();

    // Check mode validity and update context & local storage
    const updateSoundMode = (mode) => {
        if (mode === "off" || mode === "on" || mode === "loop") {
            setSoundMode(mode);
        } else {
            throw new Error(`Invalid sound mode: ${mode}`);
        }
    };

    const context = {
        isSoundEnabled: soundMode !== "off",
        isSoundLooping: soundMode === "loop",
        playSound: soundMode !== "off" && unreadEventCount,
        soundMode,
        updateSoundMode,
    };

    return (
        <SoundContext.Provider value={context} >
            {children}
        </SoundContext.Provider>
    );
}

function useSoundContext() {
    const context = useContext(SoundContext);
    if (context === undefined) {
        throw new Error('Must be used inside an SoundContext Provider');
    }
    return context;
}

export { SoundProvider, useSoundContext };
