import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Divider, Chip } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";
import { useCreateUserMutation } from "../../app/services/mastraApi";
import Typography from "@mui/material/Typography";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserDeletionList from "./UserDeletionList";

export default function ManageUsersPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [repeatPasswordError, setRepeatPasswordError] = useState(false);

    const [createUser, { isSuccess, isLoading, isError, data }] = useCreateUserMutation();



    function validate() {
        let usernameError = false;
        let passwordError = false;
        let repeatPasswordError = false;

        if (username.length < 2) {
            usernameError = true;
        }
        if (password.length < 5) {
            passwordError = true;
        }
        if (password !== repeatPassword) {
            repeatPasswordError = true;
        }

        setUsernameError(usernameError);
        setPasswordError(passwordError);
        setRepeatPasswordError(repeatPasswordError);

        return !usernameError && !passwordError && !repeatPasswordError;
    }

    function onNext() {
        if (validate()) {
            createUser({ username, password });
            setUsername("");
            setPassword("");
            setRepeatPassword("");
        }
    }

    // Notify on success
    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("User successfully added", { variant: "success" });
            // Clear the input fields
            setUsername("");
            setPassword("");
            setRepeatPassword("");

        }
    }, [isSuccess]);

    // Notify on error
    useEffect(() => {
        if (isError) {
            enqueueSnackbar(
                "Some error occurred while creating the new user",
                { variant: "error" }
            );
        }
    }, [isError]);

    return (
        <Container >
            <Stack direction="column" gap={4}>
                <Divider>
                    <Chip label="Manage users" />
                </Divider>
            </Stack>
            <Paper
                elevation={3}
                sx={{
                    bgcolor: "#fafafa",
                    borderRadius: "22px",
                    padding: "30px",
                    boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "30px",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    Create new user
                </Typography>
                <br></br>
                <TextField
                    id="standard-basic"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    error={usernameError}
                    helperText={usernameError ? "Username must be at least 2 characters long" : ""}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                />
                <br></br>
                <br></br>
                <TextField
                    id="standard-basic"
                    label="Password"
                    variant="outlined"
                    type={"password"}
                    fullWidth
                    error={passwordError}
                    helperText={passwordError ? "Password must be at least 5 characters long" : ""}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
                <br></br>
                <br></br>
                <TextField
                    id="standard-basic"
                    label="Repeat Password"
                    variant="outlined"
                    type={"password"}
                    fullWidth
                    error={repeatPasswordError}
                    helperText={repeatPasswordError ? "Passwords do not match" : ""}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                />
                <br></br>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        marginTop: "30px",
                    }}
                >
                    <Button
                        startIcon={<PersonAddIcon />}
                        variant="contained"
                        onClick={onNext}
                        disabled={isLoading}
                    >
                        Create user
                    </Button>
                </Stack>
            </Paper>
            <UserDeletionList />
        </Container >
    );
}
