import { useState } from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

/***
 * This is a generic MUI Button wrapper that allows to
 * specify a fake delay before the onClick event is fired
 * This is useful to slow down actions to provide a user feedback
 * and sense of loading.
 *
 * fakeDelayMs: number of milliseconds to wait before firing onClick
 * onClick: function to call when fake loading is done
 * loading: boolean to indicate real loading state after fake loading
 *
 * you can also pass every other MUI Button props
 */
export default function LoadingWrapper({
  fakeDelayMs = 600,
  loading: isRealLoading,
  onClick = () => { },
  children,
  askConfirm = false,
  confirmTitle = "Confirm action",
  confirmMsg = "Are you sure you want to delete this item?",
  ...other
}) {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleBtnClick = () => {
    if (askConfirm) {
      setOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    setIsFakeLoading(true);
    setTimeout(() => {
      onClick();
      setIsFakeLoading(false);
    }, fakeDelayMs);
  };

  const handleAbort = () => {
    setOpen(false);
  };

  return (
    <>
      {children({
        loading: isRealLoading || isFakeLoading,
        onClick: handleBtnClick,
        ...other,
      })}

      <Dialog
        open={open}
        onClose={handleAbort}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


const IconBtn = ({ children, ...props }) => (
  <LoadingWrapper {...props}>
    {({ loading, onClick, ...other }) => (
      <IconButton disabled={loading} onClick={onClick} {...other}>
        {children}
      </IconButton>
    )}
  </LoadingWrapper>)

const Btn = ({ children, ...props }) => (
  <LoadingWrapper {...props}>
    {({ loading, onClick, ...other }) => (
      <LoadingButton loading={loading} onClick={onClick} {...other}>
        {children}
      </LoadingButton>
    )}
  </LoadingWrapper>
)

export { Btn, IconBtn }
