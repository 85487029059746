import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Divider, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { useSubscriptionsQuery, useUpdateSubscriptionsMutation } from "../../app/services/mastraApi";
import UserSelectInput from "./UserSelectInput";
import { PlaceHolder, LoadingPlaceholder, ErrorPlaceholder } from "../PlaceHolders";
import { ListItem } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import GroupIcon from '@mui/icons-material/Group';
import Typography from "@mui/material/Typography";

const NoUserSelectedPlaceholder = () => <PlaceHolder text="Select a user to configure" icon={<GroupIcon fontSize="large" />} />

export default function ManageSubsPage() {

  const [userId, setUserId] = useState("");
  const { data: subs, isLoading, isError } = useSubscriptionsQuery({ userId }, { skip: !userId });


  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    if (subs) {
      setSubscriptions(subs);
    }
  }, [subs]);

  const [trigger, { isSuccess: isSetEventsSuccess, isError: isSetEventsError }] =
    useUpdateSubscriptionsMutation();

  // Show success/error message
  useEffect(() => {
    if (isSetEventsSuccess) {
      enqueueSnackbar("Preferences saved", { variant: "success" });
    } else if (isSetEventsError) {
      enqueueSnackbar("Error saving preferences", { variant: "error" });
    }
  }, [isSetEventsSuccess, isSetEventsError]);

  const handleSubmit = () => {
    trigger({ userId, subscriptions });
  };

  const handleSwitchChange = (index) => (event) => {
    // Create a new array with the updated subscription
    const updatedSubscriptions = subscriptions.map((sub, idx) =>
      idx === index ? { ...sub, enabled: event.target.checked } : sub
    );
    setSubscriptions(updatedSubscriptions);
  };

  return (
    <Container>
      <Stack direction="column" gap={4}>
        <Divider>
          <Chip label="User-event mapping" />
        </Divider>
        <UserSelectInput
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />

        {
          isError ? (
            // Error while fetching subscriptions
            <ErrorPlaceholder />
          ) : isLoading ? (
            // Loading subscriptions
            <LoadingPlaceholder />
          ) : (
            !userId ? (
              // No user selected
              <NoUserSelectedPlaceholder />
            ) : (
              subs ? (
                // User selected and subscriptions loaded
                <SubscriptionsForm subscriptions={subscriptions} onChange={handleSwitchChange} onSubmit={handleSubmit} />
              ) : (
                // User selected but empty subscriptions
                <>Please add events first</>
              )
            ))
        }
      </Stack>
    </Container>
  );
}


function SubscriptionsForm({ subscriptions, onChange, onSubmit }) {


  return (
    <Stack>
      <Typography variant="h6">Events visible to the selected user</Typography>
      {subscriptions.map((subscription, index) => (
        <ListItem
          key={`${subscription.id_event}-${subscription.id_cu}`}
          // key={index}
          sx={{
            bgcolor: "#fafafa",
            borderRadius: "22px",
            padding: "15px",
            boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
            width: "100%",
            mt: 2,
          }}
        >
          <FormControlLabel
            control={<Switch checked={subscription.enabled} onChange={onChange(index)} />}
            label={`CU ${subscription.id_cu}\t- Event ${subscription.event_id}\t- ${subscription.description}`}
          />
        </ListItem>
      ))}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 3 }}
      >
        <Button variant="contained" onClick={onSubmit}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
}
