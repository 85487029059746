import React, { useEffect, useState } from "react";
import { useTestCuConnectionMutation } from "../../app/services/mastraApi";
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab";

export function TestCuConnButton({ cuIp, cuPort, ...rest }) {
    const [testConnection, { data, isLoading, isSuccess, isError }] = useTestCuConnectionMutation();
    const [timeoutId, setTimeoutId] = useState(null);

    const handleTestConnection = () => {
        // Validate ip and port before sending the request
        const ipRegex = new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/);
        if (!ipRegex.test(cuIp)) {
            enqueueSnackbar("Invalid IP address", { variant: "error" });
            return;
        }

        const portRegex = new RegExp(/^[0-9]{1,5}$/);
        if (!portRegex.test(cuPort)) {
            enqueueSnackbar("Invalid port", { variant: "error" });
            return;
        }

        // Start the connection test
        const mutating = testConnection({ cuIp, cuPort });

        // Set a timeout to cancel the request if it takes too long
        const timeout = setTimeout(() => {
            mutating.abort();
            enqueueSnackbar("Connection timed out", { variant: "error" });
        }, 5000); // Timeout set for 5 seconds

        setTimeoutId(timeout);
    };

    useEffect(() => {
        if (isError || isSuccess) {
            // Clear the timeout if the request completes or fails
            clearTimeout(timeoutId);
        }

        if (isError) {
            enqueueSnackbar("Connection failed", { variant: "error" });
        } else if (isSuccess && data?.message === "pong!") {
            enqueueSnackbar("Connection successful", { variant: "success" });
        }
    }, [data, isError, isSuccess]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return (
        <LoadingButton
            {...rest}
            onClick={handleTestConnection}
            loading={isLoading}
        >
            Test
        </LoadingButton>
    );
}
