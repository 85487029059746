import React from "react";
import Backdrop from "@mui/material/Backdrop";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import { selectIsOffline, selectError } from "./offlineSlice";
import { useSelector } from "react-redux";

/**
 * This component is rendered on top of the app when the app is offline.
 * The offline state is managed by the offlineSlice.
 *
 * There must be a request or polling query that fails when the app is offline.
 * otherwise the app will never know it is offline.
 */
export default function OfflineOverlay({ children }) {
  const isOffline = useSelector(selectIsOffline);
  const error = useSelector(selectError);

  return (
    <>
      {isOffline && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <Stack alignItems="center" gap={1}>
            <WifiOffIcon fontSize="large" />
            <Typography variant="h6">
              Cannot connect to Willie Hub.
            </Typography>
            <Typography>
              Check that the server is reachable and try again.
            </Typography>
            <Typography variant="caption" sx={{ mt: 3 }} >[ Error: {error} ]</Typography>
          </Stack>
        </Backdrop>
      )}
      {children}
    </>
  );
}
