import { List } from "@mui/material";
import React, { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useUsersQuery, useDeleteUserMutation } from "../../app/services/mastraApi";
import {
  ErrorPlaceholder,
  LoadingPlaceholder
} from "../PlaceHolders";
import { ListItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconBtn } from "../Btn";
import Typography from '@mui/material/Typography';

export default function UserDeletionList(props) {
  const { data: users, isLoading, isError } = useUsersQuery();
  const [removeUser, { isLoading: isRemoveLoading, isSuccess: isRemoveSuccess, isError: isRemoveError }] = useDeleteUserMutation();


  // Show success/error message
  useEffect(() => {
    if (isRemoveSuccess) {
      enqueueSnackbar("User removed", { variant: "success" });
    } else if (isRemoveError) {
      enqueueSnackbar("Error while removing user", { variant: "error" });
    }
  }, [isRemoveSuccess, isRemoveError]);


  return (
    <>
      <Typography variant="h6">Available users</Typography>
      {isError ? (
        // Error while fetching subscriptions
        <ErrorPlaceholder />
      ) : isLoading ? (
        // Loading subscriptions
        <LoadingPlaceholder />
      ) :
        users.length === 0 ? (
          <>No users found</>
        ) : (
          <List>
            {users.map((user) => (

              <ListItem
                key={`${user.id}`}
                sx={{
                  bgcolor: "#fafafa",
                  borderRadius: "22px",
                  padding: "15px",
                  boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
                  width: "100%",
                  mt: 2,
                }}
                secondaryAction={
                  user?.username !== "admin" && (
                    <IconBtn
                      onClick={() => removeUser({ id: user.id })}
                      sx={{ ml: 2 }}
                      askConfirm
                      loading={isRemoveLoading}
                    >
                      <DeleteIcon />
                    </IconBtn>)
                }
              >
                {user.username || "No username"}
              </ListItem>
            ))
            }
          </List >
        )
      }</>)
}
