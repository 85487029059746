import React from "react";
import { useEventContext } from "./useEventContext";
import Button from "@mui/material/Button";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { enqueueSnackbar } from "notistack";

export function MarkAllAsReadButton() {
    const { markAllAsRead } = useEventContext();

    const handleMarkAllAsRead = () => {
        enqueueSnackbar("Events have been marked as seen", { variant: "success" });
        markAllAsRead();
    };

    return <Button onClick={handleMarkAllAsRead}
        variant="text"
        fullWidth
        startIcon={<DoneAllIcon />}
    >
        Mark all as seen
    </Button>;
}
