import { setOffline, setOnline } from "./offlineSlice";

const offlineMiddleware = () => (next) => (action) => {
  // make sure we listen only to rtk-query actions
  if (!action.type.startsWith("mastraApi/")) {
    // Not a rtk-query action
    return next(action);
  }

  if (action.type.endsWith("/fulfilled")) {
    // A request has been completed successfully
    next(setOnline());
  }

  if (action.type.endsWith("/rejected")) {
    // A request has been completed with an error
    if (
      action?.payload?.status === 401 ||
      action?.payload?.status === 404 ||
      action?.payload?.status === 500
    ) {
      // Unauthorized. This error is related to auth
      // and does mean that we are online
      next(setOnline());
    } else if (action?.payload.originalStatus === 504) {
      // Gateway timeout
      next(setOffline({ error: "GATEWAY TIMEOUT" }));
    } else if (action?.payload.originalStatus === 502) {
      // Bad gateway
      next(setOffline({ error: "BAD GATEWAY" }));
    } else if (action?.payload.originalStatus === 503) {
      // Service unavailable
      next(setOffline({ error: "SERVICE UNAVAILABLE" }));
    } else {
      // This is most probably a FETCH_ERROR
      // means that the user has no internet connection
      next(setOffline({ error: action?.payload.status }));
    }
  }

  return next(action);
};

export default offlineMiddleware;
