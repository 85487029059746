
import { Button } from "@mui/material";
import React, { useState } from "react";
import { Stack } from "@mui/system";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from "@mui/material/TextField";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { enqueueSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TestCuConnButton } from "./TestCuConnButton";

export default function AddTriggerDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState("");
  const [eventId, setEventId] = useState("");
  const [cuId, setCuId] = useState("");
  const [cuIp, setCuIp] = useState("");
  const [cuPort, setCuPort] = useState("8848");

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleCreateTrigger = (e) => {
    e.preventDefault();

    // Validate the form
    const formErrors = {};
    // Regex to validate IP address
    const ipRegex = new RegExp(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/);
    if (!ipRegex.test(cuIp)) {
      formErrors.cuIp = true;
    }
    // Validate the port
    const portRegex = new RegExp(/^[0-9]{1,5}$/);
    if (!portRegex.test(cuPort)) {
      formErrors.port = true;
    }
    // Validate the cuId
    const cuIdRegex = new RegExp(/^[0-9]{1,3}$/);
    if (!cuIdRegex.test(cuId)) {
      formErrors.cuId = true;
    }
    // Validate the eventId
    const eventIdRegex = new RegExp(/^[0-9]{1,3}$/);
    if (!eventIdRegex.test(eventId)) {
      formErrors.eventId = true;
    }
    // Validate the description length > 0
    if (description.length === 0) {
      formErrors.description = true;
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    const newTrigger = {
      description, cuIp, cuPort, cuId, eventId,
    };
    setOpen(false);
    setEventId("");
    setDescription("");
    props.onAdd(newTrigger);
  }

  const handleCuIpFinish = (e) => {
    // Do not do anything if the cuId is already set
    if (cuId !== "") {
      return;
    }

    // Split the IP address and get the last triplet.
    // if the triplet is not yet complete, do nothing
    // if the triplet is complete, set the cuId
    const ipTriplets = e.target.value.split(".");
    if (ipTriplets.length === 4) {
      setCuId(ipTriplets[3]);
    }
  }

  return (
    <>

      <Button fullWidth variant="contained" onClick={handleOpen}
        sx={{ mt: 3 }}
        startIcon={<PlaylistAddIcon />}
      >
        Add trigger
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="add-trigger-dialog-title"
      >
        <form onSubmit={handleCreateTrigger}>
          <DialogTitle id="add-trigger-dialog-title">
            Add new trigger
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Triggers will be saved in this browser, and will allow you to enable/disable events from this web interface.
            </DialogContentText>
            <Stack spacing={2} sx={{ my: 3 }}>
              <Stack direction={{
                xs: "column",
                sm: "row"
              }} spacing={2}>
                <TextField
                  label="Willie CU address"
                  variant="outlined"
                  fullWidth
                  value={cuIp}
                  onChange={(e) => setCuIp(e.target.value)}
                  onBlur={handleCuIpFinish}
                  placeholder="xxx.xxx.xxx.xxx"
                  error={errors?.cuIp}
                  helperText={errors?.cuIp && "Invalid IP address"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        http://
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Enter the IP address in the format: xxx.xxx.xxx.xxx">
                          <IconButton>
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
                {/* Add-on port field */}
                <TextField
                  label="Port"
                  variant="outlined"
                  sx={{ width: { sm: "100px" } }}
                  value={cuPort}
                  onChange={(e) => setCuPort(e.target.value)} // Assume you have a state setter function for port
                  error={errors?.port}
                  helperText={errors?.port && "Invalid port"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        :
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <TextField
                label="Willie CU ID"
                variant="outlined"
                fullWidth
                value={cuId}
                onChange={(e) => setCuId(e.target.value)}
                placeholder="xxx"
                error={errors?.cuId}
                helperText={errors?.cuId && "Invalid CU ID"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the ID of the Willie CU associated with this event. It is the last triplet of the CU IP Address, usually ranging from 101 to 254. E.g. if the CU IP Address is 192.168.0.101 the CU ID is 101.">
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                label="Event ID"
                variant="outlined"
                fullWidth
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}
                placeholder="xx"
                error={errors?.eventId}
                helperText={errors?.eventId && "Invalid event ID"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter the event ID configured in the Willie CU. Must be a number between 0 and 255." >
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                label="Event description"
                variant="outlined"
                fullWidth
                value={description}
                placeholder="Type something..."
                onChange={(e) => setDescription(e.target.value)}
                error={errors?.description}
                helperText={errors?.description && "Description cannot be empty"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Enter a description for this trigger. It will be displayed in the trigger list.">
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ mr: 2, mb: 2 }}>
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <TestCuConnButton cuIp={cuIp} cuPort={cuPort} />
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog >
    </>

  );
}
