import React, { useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Link, useLocation } from "react-router-dom";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../auth/useAuth";
import { useLogoutMutation } from "../../app/services/mastraApi";
import { enqueueSnackbar } from "notistack";
import Badge from '@mui/material/Badge';
import { useEventContext } from "../event/useEventContext";

export default function FixedBottomNav() {
  const location = useLocation();
  const [triggerLogout, { isSuccess }] = useLogoutMutation();
  const { isLoggedIn, isAdmin } = useAuth();
  const { unreadEventCount } = useEventContext();

  // If the view query parameter is set to kiosk, hide the bottom navigation
  const hideNavigation = new URLSearchParams(location.search).get("view") === "kiosk";

  // On successful logout, show a toast message
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Logout successful", { variant: "success" });
    }
  }, [isSuccess]);

  return (
    hideNavigation ? <></> :
      <Paper
        sx={{ position: "fixed", bottom: 0, right: 0, left: 0, }}
        elevation={3}
      >
        <BottomNavigation showLabels value={location.pathname}>
          {isLoggedIn && <BottomNavigationAction
            component={Link}
            to="/"
            value="/"
            label="Events"
            icon={
              <Badge badgeContent={unreadEventCount} color="primary">
                <ReceiptLongIcon />
              </Badge>
            }
          />}
          {isLoggedIn &&
            <BottomNavigationAction
              component={Link}
              to="/integrations"
              value="/integrations"
              label="Integrations"
              icon={<FormatListBulletedIcon />}
            />}
          {isAdmin && <BottomNavigationAction
            component={Link}
            to="/admin"
            value="/admin"
            label="Administration"
            icon={<SettingsIcon />}
          />}
          {isLoggedIn ? (
            <BottomNavigationAction
              component={Link}
              to="/"
              value="/logout"
              label="Logout"
              onClick={triggerLogout}
              icon={<LogoutIcon />}
            />
          ) : (
            <BottomNavigationAction
              component={Link}
              to="/login"
              value="/login"
              label="Login"
              icon={<LoginIcon />}
            />
          )}
        </BottomNavigation>
      </Paper>
  );
}
