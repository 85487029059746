import { createSlice } from "@reduxjs/toolkit";
import { mastraApi } from "../../app/services/mastraApi";

const initialState = {};

const updateTokenReducer = (state, action) => {
  // Login success, set user into state and store token to localStorage
  localStorage.setItem("token", action.payload.access_token);
  return state;
};

const deleteTokenReducer = (state) => {
  // Logout success, remove token
  localStorage.removeItem("token");
  return state;
};

const slice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(mastraApi.endpoints.login.matchFulfilled, updateTokenReducer)
      .addMatcher(
        mastraApi.endpoints.logout.matchFulfilled,
        deleteTokenReducer
      );
  },
});

export default slice.reducer;
