import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

function SelectInput(props) {
    const { label, options, helperText, ...rest } = props;

    return (
        <FormControl fullWidth>
            <InputLabel id={`${label}-select`}>{label}</InputLabel>
            <Select
                labelId={`${label}-select`}
                label={label}
                {...rest}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default SelectInput;