import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { mastraApi } from "./services/mastraApi";
import authReducer from "../features/auth/authSlice";
import offlineReducer from "../features/offline/offlineSlice";
import offlineMiddleware from "../features/offline/offlineMiddleware";

const store = configureStore({
  reducer: {
    auth: authReducer,
    offline: offlineReducer,
    [mastraApi.reducerPath]: mastraApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([mastraApi.middleware, offlineMiddleware]),
  enhancers: [],
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
