import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useResetEventsMutation } from "../../app/services/mastraApi";
import { enqueueSnackbar } from "notistack";
import ClearAllIcon from '@mui/icons-material/ClearAll';

export function ResetEventsButton() {
    const [resetEvents, { isSuccess, isError }] = useResetEventsMutation();

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("All active events have been cleared", { variant: "success" });
        } else if (isError) {
            enqueueSnackbar("Error resetting events", { variant: "error" });
        }
    }, [isSuccess, isError]);


    return (
        <Button
            variant="text"
            onClick={() => { resetEvents(); }}
            startIcon={<ClearAllIcon />}
            fullWidth

        >
            Force clear events
        </Button>
    );
}
