import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "offline",
  initialState: {
    isOffline: false,
    error: "",
  },
  reducers: {
    setOnline: (state) => ({
      ...state,
      isOffline: false,
    }),
    setOffline: (state, action) => ({
      ...state,
      isOffline: true,
      error: action.payload.error,
    }),
  },
});

export default slice.reducer;

export const { setOnline, setOffline } = slice.actions;

export const selectIsOffline = (state) => state.offline.isOffline;
export const selectError = (state) => state.offline.error;
