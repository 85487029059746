import React, { createContext, useContext, useState } from 'react';
import { useActiveEventsQuery } from "../../app/services/mastraApi";
import { useLocalStorage } from '../useLocalStorage';
const EventContext = createContext(undefined);

function EventProvider({ children }) {

    const [lastReadTime, setLastReadTime] = useLocalStorage('lastReadTime', new Date().toISOString());

    const {
        data: rawEvents,
        isLoading: isEventsLoading,
        isError: isEventsError
    } = useActiveEventsQuery({}, {
        pollingInterval: 1000
    });

    const markAllAsRead = () => {
        setLastReadTime(new Date().toISOString());
    }

    // events is rawEvents with the addition of the "is_read" property. true if updated_at is before lastReadTime
    const events = rawEvents?.map(e => {
        return {
            ...e,
            is_read: e.updated_at < lastReadTime
        }
    }
    ).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    const context = {
        events,
        unreadEventCount: events?.filter(e => !e.is_read).length,
        isEventsLoading,
        isEventsError,
        markAllAsRead,
    };

    return (
        <EventContext.Provider value={context} >
            {children}
        </EventContext.Provider >
    );
}

function useEventContext() {
    const context = useContext(EventContext);
    if (context === undefined) {
        throw new Error('Must be used inside an EventContext Provider');
    }
    return context;
}

export { EventProvider, useEventContext };
