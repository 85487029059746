import { useLocation } from "react-router-dom";

/**
  Hook that parses URL query params and returns
  a query object. Note: uses browser
  native API so not working in Electron i think

  for example URL:
    /foo/bar?test=1
  Returns:
    query
  usage:
    query.get("test");
*/
export const useUrlQuery = () => {
  // Get url
  const { search } = useLocation();
  // extract query params
  return new URLSearchParams(search);
};

export default useUrlQuery;
