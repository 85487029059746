import React from "react";
import { Stack, Typography } from "@mui/material";
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
export function PlaceHolder(props) {
    return <Stack
        sx={{ height: '300px', textAlign: 'center', py: 5, px: 3 }}
        alignItems="center"
        justifyContent="center"
        gap={2}
    >
        {props.icon}
        <Typography variant="h6">{props.text}</Typography>
    </Stack>;
}

export const LoadingPlaceholder = () => <PlaceHolder text="Loading..." icon={<HourglassEmptyOutlinedIcon fontSize="large" />} />
export const NoResultsPlaceholder = () => <PlaceHolder text="No results" icon={<PlaylistRemoveIcon fontSize="large" />} />
export const ErrorPlaceholder = () => <PlaceHolder text="Error loading results" icon={<ErrorOutlineOutlinedIcon fontSize="large" />} />
