import React from 'react'
import KioskModeDialog from './KioskModeDialog'
import { Container } from '@mui/material';
import { useLocalStorage } from '../useLocalStorage';
import TriggerList from './TriggerList';
import useAuth from '../auth/useAuth';
import { enqueueSnackbar } from 'notistack';
import AddTriggerDialog from './AddTriggerDialog';

export default function TriggerPage() {

    const [triggers, setTriggers] = useLocalStorage("triggers", readLegacyTriggerOrDefault());

    // If triggers is not empty, make sure to remove the legacy trigger.
    // This is done to avoid the legacy trigger to be added again.
    // this can be removed once all the machines have ran the code

    const { isAdmin } = useAuth();


    const handleAddTrigger = (newTrigger) => {
        setTriggers([...triggers, newTrigger]);
        enqueueSnackbar("Trigger added", { variant: "success" });
    }

    const handleTriggerDelete = (trigger) => {
        setTriggers(triggers.filter((elem) => elem !== trigger));
        enqueueSnackbar("Trigger deleted", { variant: "success" });
    }

    return (
        <Container>
            <KioskModeDialog />
            <TriggerList triggers={triggers} onDelete={handleTriggerDelete} />
            {isAdmin && <AddTriggerDialog onAdd={handleAddTrigger} />}
            {/* <Button
                onClick={() => {

                    //Adds a legacy trigger to the local storage
                    // for testing purposes
                    localStorage.setItem("id_event", "1");
                    localStorage.setItem("id_cu", "1");
                    localStorage.setItem("ip_cu", "http://192.168.23.191");

                }}
            >
                Add legacy trigger
            </Button> */}
        </Container>
    )
}


/**
 * If the keys "id_event", "id_cu" and "ip_cu" are
 * present in the local storage, returns a new trigger
 * array with the values. Otherwise, returns an empty
 * array.
 * Note: this function is used to read the legacy
 * Eurotermo trigger from the local storage.
 *
 * It can be removed once all the machines have ran
 * the code at least once after the update.
 */
function readLegacyTriggerOrDefault() {
    const idEvent = localStorage.getItem("id_event");
    const idCu = localStorage.getItem("id_cu");
    const ipCu = localStorage.getItem("ip_cu") || "";


    if (idEvent && ipCu && idCu) {
        // Create a new trigger
        console.log("Creating a new trigger from the legacy data");
        const newTrigger = {
            cuIp: ipCu
                // Strip the scheme
                .replace(/^(.*:\/\/)?/, "")
                // Strip port from the ip_cu
                .replace(/:(.*)/, ""),
            cuId: idCu,
            cuPort: "8848",
            eventId: idEvent,
            description: `Evento ${idEvent}`
        };
        console.log(newTrigger);

        if (localStorage.getItem("triggers")) {
            console.log("Removing legacy trigger if exists");
            localStorage.removeItem("id_event");
            localStorage.removeItem("ip_cu");
            localStorage.removeItem("id_cu");
        }

        return [newTrigger];
    } else {
        console.log("No legacy trigger found");
        return [];
    }
}