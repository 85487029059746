import React from "react";
import { Route, Redirect } from "react-router-dom";
import FixedBottomNav from "./features/navigation/FixedBottomNav";
import EventListPage from "./features/event/EventListPage";
import Login from "./features/auth/Login";
import ManageSubsPage from "./features/user/ManageSubsPage";
import AdminPage from "./features/admin/AdminPage";
import { SessionSwitchRoute } from "./features/auth/SessionSwitchRoute";
import OfflineOverlay from "./features/offline/OfflineOverlay";
import TriggerPage from "./features/trigger/TriggerPage";
import IntegrationsPage from "./features/integrations/IntegrationsPage";
import FixedAppBar from "./features/navigation/FixedAppBar";
import { Container } from "@mui/system";
import { SnackbarProvider } from 'notistack';
import { EventProvider } from "./features/event/useEventContext";
import SoundPlayer from "./features/sound/SoundPlayer";
import SoundSettingsPage from "./features/sound/SoundSettingsPage";
import { SoundProvider } from "./features/sound/useSoundContext";
import ManageUsersPage from "./features/user/ManageUsersPage";
import ManageEventsPage from "./features/event/ManageEventsPage";
import { Box } from "@mui/system";

function App() {
  return (
    <OfflineOverlay>
      <EventProvider>
        <SoundProvider>
          <div className="app">
            <SnackbarProvider autoHideDuration={3000} />
            <SoundPlayer />
            <Container>
              <FixedAppBar title="Willie" />
            </Container>
            <Box className="app-body" sx={{
              // Ensure app content does not go under the bottom nav
              pb: "80px",
            }}>
              {/* Redirect /trigger to /multitrigger in kiosk mode for backwards compatibility with Eurotermo's desktop shortcut */}
              <Route exact path="/trigger">
                <Redirect to="/multitrigger?view=kiosk" />
              </Route>

              {/* Public routes */}
              <SessionSwitchRoute exact path="/login" publicComponent={<Login />} />

              {/* Mixed Public+Private pages */}
              <Route exact path="/multitrigger" render={() => <TriggerPage />} />
              {/* <Route exact path="/legacytrigger" render={() => <LegacyTriggerPage />} /> */}

              {/* Private routes */}
              <SessionSwitchRoute exact path="/" privateComponent={<EventListPage />} />
              <SessionSwitchRoute exact path="/integrations" privateComponent={<IntegrationsPage></IntegrationsPage>} />
              {/* SoundSettingsPage */}
              <SessionSwitchRoute exact path="/integrations/sound" privateComponent={<SoundSettingsPage />} />

              {/* Admin routes */}
              <SessionSwitchRoute exact path="/admin" privateComponent={<AdminPage />} />
              <SessionSwitchRoute exact path="/admin/events" privateComponent={<ManageEventsPage />} />
              <SessionSwitchRoute exact path="/admin/users/new" privateComponent={<ManageUsersPage />} />
              <SessionSwitchRoute exact path="/admin/users/subscriptions" privateComponent={<ManageSubsPage />} />

            </Box>
            <FixedBottomNav />
          </div>
        </SoundProvider>
      </EventProvider>
    </OfflineOverlay>
  );
}

export default App;
