import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { NoResultsPlaceholder } from "../PlaceHolders";
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "../auth/useAuth";
import { IconBtn } from "../Btn"
import Stack from '@mui/material/Stack';
import { useTriggerMutation, useGetEventStatusQuery } from "../../app/services/mastraApi";
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from "@mui/lab";
import { Paper, Typography } from "@mui/material";

export default function TriggerList(props) {

  return (
    <Stack gap={3} sx={{ mt: 3 }}>
      {props.triggers.length === 0 && <NoResultsPlaceholder />}
      {props.triggers.map((elem, id) =>
        <TriggerItem
          key={`${elem.cuId}-${elem.eventId}`}
          trigger={elem}
          onDelete={() => props.onDelete(elem)}
        />)}
    </Stack>
  );
}


function TriggerItem({ onDelete, trigger, ...otherProps }) {

  const { isAdmin } = useAuth();

  return (
    <Paper
      sx={{
        bgcolor: "#fafafa",
        borderRadius: "22px",
        padding: "20px",
        boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
        width: "100%",
      }}
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems="center">
        <Avatar sx={{ bgcolor: "#1976d2" }}>E</Avatar>
        <Typography sx={{ flexGrow: 1 }}>
          {trigger.description || "Event without description"}
        </Typography>
        <Stack direction="row" spacing={1}>
          {isAdmin && (
            <IconBtn
              onClick={onDelete}
              askConfirm
              confirmTitle="Delete trigger"
              confirmMsg="Are you sure you want to delete this trigger?"
              fakeDelayMs={0}
            >
              <DeleteIcon />
            </IconBtn>)}
          <TriggerButton trigger={trigger} />
        </Stack>
      </Stack>
    </Paper >)
}

function TriggerButton({ trigger, ...otherProps }) {

  const { eventId, cuId, cuIp, cuPort } = trigger;

  const [triggerEvent, { isSuccess, isLoading: isTriggerLoading, isError: isTriggerError }] = useTriggerMutation();
  const { data: eventStatus, isLoading: isStatusLoading, isError: isStatusError } = useGetEventStatusQuery({ id_event: eventId, id_cu: cuId }, {
    pollingInterval: 1000
  });

  const [isFakeLoading, setIsFakeLoading] = useState("");

  const handleEnable = () => {
    showFakeLoading("enableAction");
    triggerEvent({ cuIp, cuPort, eventId, eventStatus: 1 });
  }

  const handleDisable = () => {
    showFakeLoading("disableAction");
    triggerEvent({ cuIp, cuPort, eventId, eventStatus: 0 });
  }

  const showFakeLoading = (actionName) => {
    setIsFakeLoading(actionName);
    setTimeout(() => {
      setIsFakeLoading("");
    }, 1500);
  }


  const isLoading = isTriggerLoading || isStatusLoading || isFakeLoading !== "";

  // isError happens if the event is not valid and disables all the buttons.
  // it does not include trigger error, which is a different state to allow
  // the user to retry the trigger action.
  const isError = isStatusError || !typeof eventStatus?.state === "boolean";

  const isEventActive = !isLoading && !isError && eventStatus?.state === true;

  const hint = isLoading ? "Loading..." :
    isStatusError ? "Event is not valid" :
      isTriggerError ? "Error triggering event" :
        "Event is " + (isEventActive ? "active" : "inactive")

  return (
    <>
      <Tooltip title={hint}>
        <div>

          <LoadingButton
            variant="contained"
            disabled={isError || isEventActive || isLoading}
            loading={isFakeLoading === "enableAction"}
            onClick={handleEnable}>
            Enable
          </LoadingButton>
        </div>
      </Tooltip>
      <Tooltip title={hint}>
        <div>
          <LoadingButton variant="contained"
            disabled={isError || !isEventActive || isLoading}
            loading={isFakeLoading === "disableAction"}
            onClick={handleDisable}>
            Disable
          </LoadingButton>
        </div>
      </Tooltip>
    </>
  )
}
