import React from "react";
import { Button, Container, Typography, Divider, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { useHistory } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ListIcon from '@mui/icons-material/List';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
export default function AdminPage() {
  const history = useHistory();


  return (
    <Container>
      <Stack direction="column" gap={4}>
        <Divider>
          <Chip label="Administration" />
        </Divider>
        <Button variant="contained" onClick={() => history.push("/admin/users/new")} startIcon={<ManageAccountsIcon />}>
          Manage users
        </Button>
        <Button variant="contained" onClick={() => history.push("/admin/events")} startIcon={<ListIcon />}>
          Manage events
        </Button>
        <Button variant="contained" onClick={() => history.push("/admin/users/subscriptions")} startIcon={<ToggleOnIcon />}>
          User-event mapping
        </Button>
      </Stack>
    </Container>
  );
}
