import * as React from "react";
import Typography from "@mui/material/Typography";
import { ReactComponent as AppLogo } from "../../assets/jw_logo_hand_crop.svg";
import { Stack } from "@mui/system";
import { Avatar } from "@mui/material";
import { useAuth } from "../auth/useAuth";


export default function FixedAppBar(props) {

  const { user } = useAuth();

  const LOGO_SZ = 48;


  return (
    <Stack
      direction="row"
      alignItems="end"
      spacing={2}
      sx={{ margin: "30px" }}
    >
      <Avatar
        variant="square"
        sx={{ width: LOGO_SZ * 0.835, height: LOGO_SZ, bgcolor: "transparent" }}
      >
        <AppLogo />
      </Avatar>
      <Typography component="h1" variant="h5" sx={{ fontFamily: "A-Space", flexGrow: 1 }}>
        {props.title}
      </Typography>
      <Typography variant="caption" align="right" sx={{ pb: 0.2 }}>
        {user?.username && <b>Welcome, {user.username}</b>}
      </Typography>
    </Stack >
  );
}
