import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import React, { } from "react";
import { Stack } from "@mui/system";
import SelectInput from "../SelectInput";
import { Divider, Chip, Paper } from "@mui/material";
import { useSoundContext } from './useSoundContext';
import { enqueueSnackbar } from "notistack";
import { FormHelperText } from '@mui/material/FormHelperText';
import { SoundPlayerTestButton } from "./SoundPlayer";

export default function SoundSettingsPage() {

    const {
        soundMode, updateSoundMode,
    } = useSoundContext();


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const soundMode = data.get('soundMode');
        updateSoundMode(soundMode);
        enqueueSnackbar("Sound mode successfully updated", { variant: "success" });
    }

    return (

        <Container sx={{ my: 2 }}>

            <Stack gap={4}>
                <Divider>
                    <Chip label="Sound integration" />
                </Divider>
                <form onSubmit={handleSubmit}>
                    <Stack alignItems={"end"} gap={2}>
                        <SelectInput
                            label="Sound mode"
                            name="soundMode"
                            helperText="Settings apply to the current browser only"
                            defaultValue={soundMode || ""}
                            options={[
                                { value: "off", label: "Disabled" },
                                { value: "on", label: "Enabled (single)" },
                                { value: "loop", label: "Enabled (loop)" },]}
                        />
                        <Stack direction="row" gap={2}>
                            <SoundPlayerTestButton />
                            <Button variant="contained" type="submit">
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Container >
    );
}
