import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Divider, Chip } from "@mui/material";
import { useCreateEventTypeMutation } from "../../app/services/mastraApi";
import { enqueueSnackbar } from "notistack";
import EventDeletionList from "./EventDeletionList";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

export default function ManageEventsPage() {
    const [id, setId] = useState("");
    const [id_cu, setIdCu] = useState("");
    const [description, setDescription] = useState("");

    // Define new state variables for each input field error
    const [idError, setIdError] = useState("");
    const [idCuError, setIdCuError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const [create, { isSuccess, isLoading, isError, data }] =
        useCreateEventTypeMutation();

    // Create a validate function that checks all input fields and updates the error state variables accordingly
    const validate = () => {
        let idError = "";
        let idCuError = "";
        let descriptionError = "";

        if (isNaN(parseInt(id)) || parseInt(id) < 0) {
            idError = "Willie event ID must be a positive integer";
        }

        if (isNaN(parseInt(id_cu)) || parseInt(id_cu) < 1 || parseInt(id_cu) > 254) {
            idCuError = "Willie CU ID must be an integer between 1 and 254";
        }

        if (description.length < 1) {
            descriptionError = "Event description cannot be empty";
        }

        setIdError(idError);
        setIdCuError(idCuError);
        setDescriptionError(descriptionError);

        return !idError && !idCuError && !descriptionError;
    };

    function onNext() {
        // Call the validate function before submitting
        if (validate()) {
            create({ id, id_cu, description });
        }
    }

    // Notify on success
    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar("Event successfully added", { variant: "success" });
            setId("");
        }
    }, [isSuccess]);

    // Notify on error
    useEffect(() => {
        if (isError) {
            enqueueSnackbar(
                "Some error occurred while creating the new event",
                { variant: "error" }
            );
        }
    }, [isError]);

    return (
        <Container>
            <Divider>
                <Chip label="Manage events" />
            </Divider>
            <Paper
                elevation={3}
                sx={{
                    bgcolor: "#fafafa",
                    borderRadius: "22px",
                    padding: "30px",
                    boxShadow: "0px 0px 25px 0px rgba(0,0,0,0.1)",
                    width: "100%",
                    marginTop: "30px",
                    marginBottom: "30px",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    Create new event
                </Typography>
                <br />
                <TextField
                    id="id_cu"
                    label="Willie CU ID"
                    variant="outlined"
                    fullWidth
                    value={id_cu}
                    // Add onChange event handler and error helper text
                    onChange={(e) => {
                        setIdCu(e.target.value);
                        setIdCuError("");
                    }}
                    error={Boolean(idCuError)}
                    helperText={"An integer between 1 and 254 representing the ID of the control unit that will handle this event."}
                />
                <br></br>
                <br></br>
                <TextField
                    id="id"
                    label="Willie Event ID"
                    variant="outlined"
                    fullWidth
                    // Add onChange event handler and error helper text
                    onChange={(e) => {
                        setId(e.target.value);
                        setIdError("");
                    }}
                    value={id}
                    error={Boolean(idError)}
                    helperText={"A positive integer representing the event ID"}
                />
                <br></br>
                <br></br>
                <TextField
                    id="description"
                    label="Event description"
                    variant="outlined"
                    fullWidth
                    value={description}
                    // Add onChange event handler and error helper text
                    onChange={(e) => {
                        setDescription(e.target.value);
                        setDescriptionError("");
                    }}
                    error={Boolean(descriptionError)}
                    helperText={"A short description of the event. Cannot be empty."}
                />
                <br></br>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        marginTop: "30px",
                    }}
                >
                    <Button
                        startIcon={<PlaylistAddIcon />}
                        variant="contained"
                        onClick={onNext}
                        disabled={isLoading}
                    >
                        Create event
                    </Button>
                </Stack>
            </Paper>
            <EventDeletionList />
        </Container>
    );
}
